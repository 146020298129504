.navbar-default {
  top: $topBarHeight;
  background-color: white;
  border: none;

  .navbar-header {
    margin-left: 15px;
    height: 60px;

    .navbar-brand {
      height: 100%;
      padding: 10px;

      .logo {
        height: 100%;
      }

    }

    .navbar-toggle {
      background-color: $blue;
      border: none;
      margin-top: 13px;

      .icon-bar {
        background-color: white;
      }
    }
  }
  .navbar-collapse {

    .navbar-nav {
      margin-top: 0;

      .active > a:hover {
        background-color: $blue;
        color: white;
      }
    }

    .active > a, .active > a:focus {
      background-color: $blue;
      color: white;
    }

    li a {
      color: #333;
      padding: 20px 15px;

      &:hover {
        color: $blue;
      }
    }
  }
}

@media #{$mobile} {
  .navbar-default {
    top: $topBarHeightMobile;
    .navbar-collapse {
      li a {
        padding: 10px;
      }
    }
  }

}

@media #{$tabletLandscape} {
  .navbar-default {
    top: $topBarHeightTabletLandscape;
  }
}