.pricesPackages {
  padding-bottom: 10px;
  text-align: center;

  .pricesPackage {
    padding: 20px 50px;

    .panel-default {
      border-radius: 0;
      border: none;
      margin: 0 40px;
      -webkit-box-shadow: 3px 3px 10px 0 rgba(173, 173, 173, 1);
      -moz-box-shadow: 3px 3px 10px 0 rgba(173, 173, 173, 1);
      box-shadow: 3px 3px 10px 0 rgba(173, 173, 173, 1);

      .panel-heading {
        background-color: $blue;
        color: white;
        border-radius: 0;
        border-bottom: 0;
        padding: 17px 15px;
      }

      .panel-body {
        padding: 0;
        border-top: 0;
        min-height: 200px;

        .table {
          margin-bottom: 0;

          tr {

            td {
              border-top: 0;
              padding: 20px;
            }

            span {
              color: #777777;
              font-size: 12px;
            }
          }

        }
      }
      .panel-footer {
        color: $blue;
        background-color: white;
        border-top: 0;
        margin-top: 50px;
        border-radius: 0;
      }
    }
  }
}

@-webkit-keyframes scale {
  from {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.05);
  }
}

@media #{$mobile} {
  .pricesPackage {
    padding: 20px 30px !important;
  }
}

@media #{$tabletLandscape} {
  .panel-body {
    min-height: 260px !important;
  }
}