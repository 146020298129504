.pricesMain {
  text-align: center;
  padding: 40px 20px 20px;
}

.globalPricesArea {
  background: url('../img/paal.png') no-repeat right bottom;
  background-size: 20%;

  .globalPrices {
    margin-top: 20px;
    margin-bottom: 40px !important;
    tr {
      span {
        color: #333;
      }
      td {
        border: 1px solid $blue !important;
        padding: 10px 20px;
      }
    }
  }
}


@media #{$mobile} {
  .globalPricesArea {
   background-position: center bottom;
    background-size: 30%;
    padding: 0 20px 40px;
    margin: 0 auto;
  }
}

