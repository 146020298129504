.overText {
  padding: 40px 5px 0;
  margin-bottom: -1px;
  h3 {
    font-weight: bold;
    margin-top: 40px;
  }
  .signs {
    margin-top: 70px;
  }
}

@media #{$mobile} {
  .overText {
    padding-top: 0;

    .signs {
      margin-top: 40px;
    }
  }

}

.register {
  background-color: $blue;
  text-align: center;
  padding: 20px 20px;
  font-weight: bold;

  h3 {
    line-height: 35px;
    .black {
      color: black;
    }

    .white {
      color: white;
    }
  }

  .submitButton {
    padding: 20px 0;
    button {
      background-image: url('../img/kenteken.png');
      background-size: 100% auto;
      width: 186px;
      height: 40px;
      color: black;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      padding-left: 30px;
      border-radius: 0;
      border: none;

      &:hover {
        color: #2463a8;
      }
    }
  }
}

.imageGallery {
  padding: 40px 0;

  img {
    -webkit-filter: grayscale(1);
    -webkit-box-shadow: 0 2px 6px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0 2px 6px 2px rgba(0,0,0,0.75);
    box-shadow: 0 2px 6px 2px rgba(0,0,0,0.75);
    margin-bottom:20px;
  }

  img:hover {
    filter: none; /* IE6-9 */
    -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
  }
}
