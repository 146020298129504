/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: $footerHeight;
}

@media #{$mobile} {
  body {
    margin-bottom: $footerHeightMobile - $footerHeight;

  }

  .footer, .push {
    height: $footerHeight !important;

  }

  .footerContent {
    height: $footerContentHeightMobile !important;
  }

  footer {
    height: $footerContentHeight;
    bottom: $footerHeight;
  }
}

.copyright {
  background-color: $blue;
}

.footer, .push {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: $footerHeight;
  background-color: $blue;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

.text-muted {
  margin: 10px 0;
  font-weight: bold;
  color: white;

  a {
    color: white;
  }
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}

footer {
  height: $footerHeight;
  color: white;
}

.footerContent {
  height: $footerContentHeight;
  font-weight: normal;
  background-image: url('../img/footerbg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  padding: 30px 0;

  .facebook {
    padding-top: 10px;
  }

  th {
    font-weight: normal;
  }

  ul {
    li {
      padding-bottom: 10px;
      &:first-child {
        padding-top: 10px;
      }
      a {
        color: white;
      }
    }
  }
}
