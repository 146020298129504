.contactMain {
  padding: 40px 0;

  h2 {
    padding: 0 20px;
  }
}

.formContact {
  margin-bottom: 30px;
}
@media #{$mobile} {
  .formContact {
    margin-bottom: 60px;
  }
}


.contactDetails {

  table {
    border-spacing: 10px;
    border-collapse: separate;

    td {
      padding: 10px;
    }
    
    a {
      color: inherit;
      text-decoration: none;
      cursor: default;
    }


    i.fa.fa-home {
      font-size: 16px;
    }

    i.fa.fa-phone {
      font-size: 15px;
    }

    i.fa.fa-mobile-phone {
      font-size: 18px;
    }
  }

}