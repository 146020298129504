.autoContainer {
  padding: 60px 20px 20px;

  .fullImage, .fullImage2 {
    text-align: center;
  }

  .row {
    padding-bottom: 30px;
  }

  h4 {
    margin-bottom: 40px;
    text-align: center;
  }

  .fullImage, .fullImage2 {
    padding-bottom: 50px;
    img {
      max-width: 100%;
    }
  }

  .carThumbs, .carThumbs2 {
    padding-bottom: 50px;
    .col-md-4.col-sm-4.col-xs-6 {
      padding: 0;
    }
    img {
      padding: 0 5px 10px;
    }
  }

  .carsDetails {
    padding-top: 20px;

    .carDetails {
      padding: 0 20px 20px;



      .table-bordered > tbody > tr > td, .table-bordered > thead > tr > th {
        border-color: $blue !important;
      }
      .table > thead:first-child > tr:first-child > th {
        border-top: 1px solid $blue;
      }

    }
  }

}

@media #{$desktop} {

  .autoContainer {
    .row {
      padding-bottom: 0;

    }
    .carsDetails {
      padding-top: 0;
    }

  }
  .fullImage {
    padding-right: 50px;
    text-align: center;
    img {
      height: 272px;
    }
  }

  .fullImage2 {
    padding-left: 50px;
    text-align: center;

    img {
      height: 272px;
    }
  }
  .carThumbs2 {
    padding-bottom: 50px;
  }
  .carThumbs {
    padding: 20px 0;
  }

}
