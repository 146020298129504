

.formSettings {
  color: $blue;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .has-feedback label ~ .form-control-feedback {
    top: 35px;
  }

  button {
    background-image: url('../img/kenteken.png');
    background-size: 100% auto;
    width: 186px;
    height: 40px;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    padding-left: 30px;
    border-radius: 0;
    border: none;


    &:hover {
      color: #2463a8;
    }
  }
}