html, body {
  width: 100%;
  padding: 0;
  margin: 0;
}

html {
  -webkit-text-size-adjust: none
}

.fContainer {
  margin-top: $topBarHeight + $navHeight;
}

.lContainer {
  padding-bottom: 50px;
}

@media #{$tabletLandscape} {
  .fContainer {
    margin-top: $topBarHeightTabletLandscape + $navHeightTabletLandscape !important;
  }

}

@media #{$mobile} {
  .fContainer {
    margin-top: $topBarHeightMobile + $navHeight;
  }
  .mCenter {
    text-align: center;
  }
}

.p20 {
  padding: 20px;
}
h1 {
  padding: 0 15px;
}

.pageTitle {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background: url('../img/pageHeader.jpg') no-repeat center;
  color: white;
  font-weight: bold;

  h1 {
    margin: 0;
    font-weight: 600;
  }
}

hr {
  border-top: 1px solid $blue;
}
textarea { resize:vertical ; }