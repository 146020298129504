.sArea {
  background-image: url('../img/road.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom center;

  padding: 20px;

  h1 {
    padding-top: 150px;
    padding-bottom: 30px;
    color: white;
    font-weight: bold;
  }
}

@media #{$mobile} {
  .sArea {
    background-image: url('../img/roadMobile.jpg');
    background-attachment: scroll;
    background-size: auto 100%;

    h1 {
      padding-top: 0;
    }
  }
}

.formContainer {
  background-color: white;
  padding: 20px;

  -webkit-box-shadow: 5px 15px 10px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 5px 15px 10px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 5px 15px 10px 0 rgba(0, 0, 0, 0.35);
  margin: 20px 0 -50px;

  .formText {
    color: black;
    margin-bottom: 20px;

  }

  h4 {
    font-weight: bold;
  }

  hr {
    border-top: 1px solid $blue;
    margin: 0 0 20px;
  }


}

.mainTextArea {
  background-image: url('../img/mtImg.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  height: 150px;

  h1 {
    margin-top: 0;
    padding: 54px 20px;
    color: black
  }
}

@media #{$mobile} {
  .mainTextArea {
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

span {
  color: $blue;
}

.jos {
  max-width: 70%;
}


#readMore {
  margin-bottom: 60px;
}

.btn-default {
  background-color: $blue;
  color: white;
  border: 1px solid $blue;
  border-radius: 0;
  margin-bottom: 20px;

  &:hover {
    background-color: white !important;
    color: $blue;
    border-color: $blue;
  }
}


.packageContainer {
  background-image: url('../img/road2.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.80);
  text-align: center;
  padding: 20px 0;

  .panel {
    margin: 20px 0;
  }
}

@media #{$mobile} {
  .packageContainer {
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.packages {
  width: 40%;
}

@media #{$mobile} {
  .packages {
    width: 60%;
  }
}

.bSeePackages {
  margin: 30px 0 40px;
}

.homePackages {
  margin-top: 40px;
}